import Vue from "vue";
import Vuex from "vuex";
import RESTService from "@/services/RESTServices.js";
import VueCryptojs from "vue-cryptojs";

Vue.use(Vuex);
Vue.use(VueCryptojs);

//import pathify from "vuex-pathify";
//import VueCryptojs from "vue-cryptojs";
//Vue.use(VueCryptojs);
/*
into the SET_USER_DATA
const userDataProtected = Vue.CryptoJS.AES.encrypt(
  JSON.stringify(userData),
  "Secret Passphrase"
).toString();
console.log(userDataProtected);
*/

export default new Vuex.Store({
  //plugins: [pathify.plugin],
  state: {
    user: null,
    networkError: true,
    connectedToInternet: true,
    zones: false,
  },

  mutations: {
    NO_INTERNET(state) {
      state.connectedToInternet = false;
      state.networkError = true;
    },
    CONNECTED_TO_INTERNET(state) {
      state.connectedToInternet = true;
      state.networkError = false;
    },
    SET_USER_DATA(state, userData) {
      RESTService.setJWT(userData.token);
      state.user = userData;
      //delete state.user.token;
      state.zones = userData.zones;
      //delete state.user.zones;
      localStorage.setItem(
        "user",
        Vue.CryptoJS.AES.encrypt(
          JSON.stringify(userData),
          "L!vePlus_5637885046816768"
        ).toString()
      );
    },
    CLEAR_USER_DATA() {
      localStorage.removeItem("user");
      location.reload();
    },
  },

  actions: {
    noInternet({ commit }) {
      commit("NO_INTERNET");
    },
    connectedToInternet({ commit }) {
      commit("CONNECTED_TO_INTERNET");
    },
    login({ commit }, data) {
      commit("SET_USER_DATA", data);
    },
    logout({ commit }) {
      commit("CLEAR_USER_DATA");
    },
  },
  getters: {
    zones(state) {
      return state.zones;
    },
    userID(state) {
      return state.user;
    },
    connectedToInternet(state) {
      return state.connectedToInternet;
    },
    loggedIn(state) {
      return !!state.user;
    },
  },
  modules: {},
});
