<template>
  <v-container messages>
    <v-row>
      <v-col cols="12">
        <h1>Broadcast Messenger</h1>
        <v-divider></v-divider>
        <v-progress-linear
          v-if="!connectedToInternet"
          color="orange"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>

    <template>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <v-dialog v-model="dialog" persistent max-width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                fab
                dark
                large
                color="orange darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>mdi-pencil</v-icon>
              </v-btn>
            </template>

            <validation-observer ref="mailObserver" v-slot="{ invalid }">
              <v-card>
                <v-card-title class="orange lighten-5">
                  <span class="text-h5">New Message</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-container>
                  <v-card-text>
                    <v-form ref="newMessage">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Subject"
                        :rules="{
                          required: true,
                          max: 40,
                          min: 4,
                          regex: `^(\\w|\\d|\\s|\\+|\\-|\\(|\\)|\\%|\\#|\\$|\\&|\\.|\\;|\\:|\\!|\\ñ|\\')+$`,
                        }"
                      >
                        <v-text-field
                          v-model="Title"
                          class="text-h5 pt-3"
                          label="Subject"
                          solo
                          required
                          counter="40"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>

                      <validation-provider
                        v-slot="{ errors }"
                        name="Message"
                        :rules="{
                          required: true,
                          max: 255,
                          min: 4,
                          regex: `^(\\w|\\d|\\s|\\,|\\+|\\-|\\(|\\)|\\%|\\#|\\$|\\&|\\.|\\;|\\:|\\!|\\ñ|\\')+$`,
                        }"
                      >
                        <v-textarea
                          v-model="Message"
                          label="Message"
                          type="text"
                          required
                          solo
                          auto-grow
                          counter="256"
                          :error-messages="errors"
                        ></v-textarea>
                      </validation-provider>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <span class="text-body-1 pl-3 pr-3"
                      >Non erasable by user</span
                    >
                    <v-checkbox v-model="msgPermanent"></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="pr-8"
                      color="blue darken-1"
                      text
                      @click="msgClose"
                      >Close
                    </v-btn>
                    <v-btn
                      text
                      color="blue darken-1"
                      :disabled="invalid"
                      @click="submit"
                      >Send
                    </v-btn>
                  </v-card-actions>
                </v-container>
              </v-card>
            </validation-observer>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <template v-if="mailList.length > 0">
            <v-timeline>
              <v-timeline-item
                v-for="mail in mailList"
                :key="mail._id"
                color="white"
                large
              >
                <template v-slot:icon>
                  <v-progress-circular
                    rotate="-90"
                    size="100"
                    width="8"
                    :value="mail.receivedPerCengage"
                    color="orange darken-2"
                    class="text-body-2"
                    >{{ mail.receivedPerCengage }}
                  </v-progress-circular>
                </template>
                <template v-if="mail.receiverCount > 0" v-slot:opposite>
                  <span>
                    Read by {{ mail.receiverCount }} out of {{ mail.usersSent }}
                  </span>
                </template>
                <template v-else v-slot:opposite>
                  <span>
                    <p class="grey--text font-italic">No readers yet</p>
                  </span>
                </template>

                <v-card class="elevation-3">
                  <v-card-title class="orange lighten-2">
                    <v-icon v-if="mail.erasable" dark right class="pr-4"
                      >mdi-delete-forever
                    </v-icon>
                    <v-icon v-if="!mail.erasable" dark right class="pr-4"
                      >mdi-information-variant
                    </v-icon>
                    {{ mail.title }}
                  </v-card-title>
                  <v-card-subtitle class="orange lighten-2">
                    <v-icon right class="pr-9"></v-icon>
                    {{
                      new Date(mail.epoch).toDateString() +
                      ", " +
                      new Date(mail.epoch).toLocaleTimeString() +
                      ". (" +
                      moment(mail.epoch).fromNow() +
                      ")"
                    }}
                  </v-card-subtitle>

                  <v-card-text class="pa-3">
                    {{ mail.message }}
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </template>
          <template v-else>
            <v-row justify="center">
              <span class="orange--text text-h5 pt-10 text-center"
                >No Messages Sent Yet</span
              >
            </v-row>
          </template>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import { required, max, min, regex } from "vee-validate/dist/rules";
import { getUserID, connectedToInternet } from "@/store/helpers";
import RESTService from "@/services/RESTServices.js";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("aggressive");

//'^(\w|\d|\s|\+|\-|\(|\)|\%|\#|\$|\&|\.|\;|\:|\!|\ñ)
extend("regex", {
  ...regex,
  message:
    "{_field_} can only Alphabetical letters, Numbers or ) ( : ; ! # & % - ' + ñ ,",
});
extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});
extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});
extend("min", {
  ...min,
  message: "{_field_} may not be less than {length} characters",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    mailList: null,
    dialog: false,
    msgPermanent: null,
    Title: null,
    Message: null,
  }),
  computed: {
    ...getUserID,
    ...connectedToInternet,
  },
  created() {
    this.loadData();
    this.interval = setInterval(() => {
      this.loadData();
    }, 5000); //5 sec
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    loadData() {
      RESTService.getMail(this.userID._id).then(({ data }) => {
        this.mailList = data;
      });
    },
    async submit() {
      const isValid = await this.$refs.mailObserver.validate();
      if (isValid) {
        RESTService.postMail(this.$store.state.user._id, {
          toOrganization: this.$store.state.user.organization,
          title: this.Title,
          text: this.Message,
          permanent: !!this.msgPermanent,
        })
          .then(() => {
            this.loadData();
            this.dialog = false;
            this.Title = "";
            this.Message = "";
            this.msgPermanent = false;
            requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
            //alert("Message sent!");
          })
          .catch((e) => {
            alert("An error happened, please try again later.\\\n" + e);
          });
      } else {
        alert("Data Needed");
      }
    },
    msgClose() {
      this.dialog = false;
      this.Title = "";
      this.Message = "";
      this.msgPermanent = false;
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>
