<template>
  <v-card
    class="mx-auto"
    max-width="500"
    min-width="300"
    elevation="8"
    outlined
    shaped
  >
    <v-card-title class="font-weight-bold text-capitalize">
      {{ stat.name }}
      <template v-if="!!stat.dir">
        <v-spacer></v-spacer>
        <v-icon
          v-if="stat.dir === 1"
          large
          color="green lighten-2"
          class="pl-4"
        >
          mdi-trending-up
        </v-icon>
        <v-icon v-if="stat.dir === 0" large color="blue lighten-2" class="pl-4">
          mdi-approximately-equal
        </v-icon>
        <v-icon v-if="stat.dir === -1" large color="red lighten-2" class="pl-4">
          mdi-trending-down
        </v-icon>
      </template>
    </v-card-title>
    <v-card-text>
      <v-row align="center" no-gutters>
        <v-col class="text-subtitle-1 text-center" cols="6"
          >Subscriptions
        </v-col>
        <v-divider vertical></v-divider>
        <v-col class="text-caption text-center" cols="6">Agent Average</v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters class="pb-3">
        <v-col class="text-h3 text-center" cols="6">
          <p>
            {{
              stat.subscriptions === null
                ? "0"
                : stat.subscriptions.toLocaleString()
            }}
          </p>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col class="text-h4 text-center" cols="6">
          <p>
            {{
              stat.subsAgent === null ? "0" : stat.subsAgent.toLocaleString()
            }}
          </p>
        </v-col>
      </v-row>

      <!--      <v-row no-gutters>
        <v-col class="text-subtitle-1" align="center" cols="6"
          >Subscriptions
        </v-col>
        <v-divider vertical></v-divider>
        <v-col class="text-caption" align="center" cols="6"> by Agent</v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters>
        <v-col class="text-h6" align="center" cols="6">
          {{ stat.subs.toLocaleString() }}
        </v-col>
        <v-divider vertical></v-divider>
        <v-col class="text-h6" align="center" cols="6">
          {{ stat.subsAgent.toLocaleString() }}
        </v-col>
      </v-row>-->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SalesCard",
  props: {
    stat: Object,
  },
};
</script>
