<template>
  <v-container>
    <v-row class="text-left">
      <v-col cols="12">
        <h1>PlusPoints Calculator</h1>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <h3>
          1 PlusPoint in PHP equals to:
          {{ plusPointRate }}
        </h3>
        <h3 class="pt-1">
          Subscription Value: {{ productValue }} PlusPoints, P{{
            numeral(calculatorData.PackagePHP100CH).format("0,0.00")
          }}
        </h3>
        <h3 class="pt-1">
          Points in Stock: {{ numeral(points).format("0,0") }}
        </h3>
      </v-col>
    </v-row>
    <v-row class="pt-6">
      <v-card class="mx-auto" min-width="400" max-width="700" outlined>
        <div class="ma-5">
          <div class="text-overline mb-4">press ENTER to calculate</div>
          <v-text-field
            label="PlusPoints"
            v-model="plusPoints"
            @keyup="plusPointsKeys"
            class="mx-auto"
            outlined
            rounded
          ></v-text-field>
          <v-text-field
            :label="`Subscriptions (At ${this.productValue} PlusPoints per subscription)`"
            v-model="subscriptions"
            @keyup="subscriptionsKeys"
            rounded
            outlined
          ></v-text-field>
          <v-range-slider
            v-model="range"
            color="orange"
            :label="`AgentPower: ${numeral(this.salesPower).format('0.0')}`"
            hint="Agent's Daily Sales Power"
            min="15"
            max="50"
            thumb-label
          ></v-range-slider>
          <v-text-field
            :label="`Days Of Operation (${this.teamSize} sellers)`"
            v-model="daysOfOperation"
            rounded
            disabled
            outlined
          ></v-text-field>
          <v-slider
            v-model="discount"
            color="orange"
            :label="`Discount: ${this.discount}%`"
            hint="Time to dream..."
            min="0"
            value="15"
            max="30"
            thumb-label
          ></v-slider>
          <v-text-field
            label="PHP"
            disabled
            v-model="PHP"
            rounded
            outlined
          ></v-text-field>
          <v-text-field
            label="USD"
            disabled
            v-model="USD"
            rounded
            outlined
          ></v-text-field>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { getUserID, connectedToInternet } from "@/store/helpers";
import numeral from "numeral";
import RESTService from "@/services/RESTServices";

export default {
  name: "calculator",
  data: () => ({
    range: [25, 40],
    discount: 15,
    productValue: null,
    plusPointRate: null,
    teamSize: null,
    points: null,
    plusPoints: null,
    subscriptions: null,
    calculatorData: null,
    pp: 0,
  }),
  computed: {
    ...getUserID,
    ...connectedToInternet,
    salesPower() {
      return (this.range[0] + this.range[1]) / 2;
    },
    daysOfOperation() {
      return numeral(
        this.pp / this.productValue / this.salesPower / this.teamSize
      ).format("0,0.0");
    },
    USD() {
      const dis = this.discount / 100;
      return numeral(this.pp / 100 - (this.pp / 100) * dis).format("$0,0.00");
    },
    PHP() {
      const dis = this.discount / 100;
      return (
        "₱" +
        numeral(
          (this.pp / 100 - (this.pp / 100) * dis) * this.plusPointRate * 100
        ).format("0,0.00")
      );
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    plusPointsKeys: function (key) {
      console.log(key.keyCode);
      if (key.keyCode === 13) {
        this.pp = Number(this.plusPoints.replace(/,/g, ""));
        this.plusPoints = numeral(this.pp).format("0,0");
        this.subscriptions = numeral(this.pp / this.productValue).format("0,0");
        this.daysOfOperation = numeral(
          this.pp / this.productValue / this.salesPower / this.teamSize
        ).format("0,0.0");
        console.log("plusPointsKeys");
      }
    },
    subscriptionsKeys: function (key) {
      if (key.keyCode === 13) {
        const sub = Number(this.subscriptions.replace(/,/g, ""));
        this.subscriptions = numeral(sub).format("0,0");
        this.plusPoints = numeral(sub * this.productValue).format("0,0");
        this.daysOfOperation = numeral(
          sub / this.salesPower / this.teamSize
        ).format("0,0.0");
        console.log("subscriptionsKeys");
      }
    },
    loadData() {
      RESTService.getCalculatorData(this.userID._id).then(({ data }) => {
        this.calculatorData = data;
        this.plusPointRate = data.PlusPointsPHPCurrency;
        this.teamSize = data.teamSize;
        this.points = data.points;
        this.productValue = data.ProductValue;
        //console.dir(data);
      });
    },
  },
};
</script>
