import Vue from "vue";
import VueRouter from "vue-router";
import VueCryptojs from "vue-cryptojs";
import Dashboard from "@/views/Dashboard.vue";
import Status from "@/views/Status.vue";
import Products from "@/views/Products.vue";
import Calculator from "@/views/Calculator.vue";
import Messages from "@/views/Messages.vue";
import Logging from "@/views/logging.vue";
import Transfer from "@/views/Transfers";
import Marketing from "@/views/Marketing";
import FAQ from "@/views/faq.vue";
import store from "@/store/index";

Vue.use(VueRouter);
Vue.use(VueCryptojs);

const routes = [
  {
    path: "/",
    name: "Logging",
    component: Logging,
    meta: { requiresAuth: false },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/Status",
    name: "Status",
    component: Status,
    meta: { requiresAuth: true },
  },
  {
    path: "/Calculator",
    name: "Calculator",
    component: Calculator,
    meta: { requiresAuth: true },
  },
  {
    path: "/messages",
    name: "Messages",
    component: Messages,
    meta: { requiresAuth: true },
  },
  {
    path: "/transfer",
    name: "Transfer",
    component: Transfer,
    meta: { requiresAuth: true },
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
    meta: { requiresAuth: true },
  },
  {
    path: "/marketing",
    name: "Marketing",
    component: Marketing,
    meta: { requiresAuth: true },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.user) {
    let data = null;
    try {
      data = JSON.parse(
        Vue.CryptoJS.AES.decrypt(
          localStorage.getItem("user"),
          "L!vePlus_5637885046816768"
        ).toString(Vue.CryptoJS.enc.Utf8)
      );
    } catch (e) {
      if (to.fullPath !== "/") {
        localStorage.removeItem("user");
        next("/");
      }
    }
    if (data !== null && to.fullPath !== "/") {
      await store.dispatch("login", data);
    } else {
      if (to.meta.requiresAuth) {
        next("/");
      }
    }
  }
  next();
});
export default router;
