<template>
  <v-col>
    <v-card class="mx-auto" width="256" color="#f5f5f5">
      <v-img :src="img" height="144px" contain></v-img>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-subtitle>{{ subTitle }}</v-card-subtitle>
      <v-card-actions class="pt-0">
        <span class="text-caption">Download</span>
        <v-btn icon :href="link">
          <v-icon color="primary lighten-2">mdi-cloud-download-outline</v-icon>
        </v-btn>

        <span class="text-caption pl-2">Copy</span>
        <v-btn icon @click="copyText">
          <v-icon color="primary lighten-2">mdi-link-variant</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <span class="text-caption">Info</span>
        <v-btn icon @click="show = !show">
          <v-icon color="primary"
            >{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>

          <v-card-text>
            {{ info }}
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "MarketingAsset",
  props: ["img", "title", "subTitle", "link", "info"],
  data: () => ({
    show: false,
  }),
  methods: {
    copyText() {
      navigator.clipboard.writeText(this.link);
    },
  },
};
</script>

<style scoped></style>
