var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-left"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Team Management & PlusPoints Transfers")]),_c('v-divider')],1)],1),_c('v-row',{staticClass:"text-left"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-overlay',{attrs:{"absolute":_vm.absolute,"value":_vm.eMailOverlay}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success"},on:{"click":function($event){_vm.eMailOverlay = false}}},[_vm._v(" Welcome eMail Sent to "+_vm._s(_vm.eMailSentTo)+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-close-circle ")])],1)],1),_c('v-data-table',{staticClass:"elevation-3",attrs:{"item-key":"_id","headers":_vm.headers,"items":_vm.dealersList,"sort-by":"fullName","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText,"loading":!_vm.connectedToInternet,"no-data-text":"No Team Members yet, add members using the Blue Button adobe","loading-text":"Connecting... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Active Team")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"outlined":"","color":"primary","dark":""},on:{"click":_vm.newUserDialog}},[_c('v-icon',[_vm._v("mdi-account-plus")])],1),_c('validation-observer',{ref:"observerNew",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalidToAddNew = ref.invalid;
return [_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogNew),callback:function ($$v) {_vm.dialogNew=$$v},expression:"dialogNew"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Add Team Member")])]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',{staticClass:"pt-6"},[_c('validation-provider',{attrs:{"name":"Nick Name","vid":"newUserUserName","rules":"required|alpha_spaces|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autofocus":true,"error-messages":errors,"label":"Nick Name ( note:This will become the Logging ID )"},on:{"blur":_vm.checkUserNameIsTaken},model:{value:(_vm.newUserName),callback:function ($$v) {_vm.newUserName=$$v},expression:"newUserName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Full Name","vid":"newUserFullName","rules":"required|alpha_spaces|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Full Name"},model:{value:(_vm.newUserFullName),callback:function ($$v) {_vm.newUserFullName=$$v},expression:"newUserFullName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"eMail","vid":"newUserEMail","rules":"required|email|min:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"eMail"},model:{value:(_vm.newUserEMail),callback:function ($$v) {_vm.newUserEMail=$$v},expression:"newUserEMail"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Phone Number","vid":"newUserPhoneNumber","rules":{
                        required: true,
                        min: 6,
                        regex: "^\\+\\d(\\d|\\s|\\(|\\)|\\-)+$",
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Phone Number i.e. +63(222)333-4444 or +16509968374"},model:{value:(_vm.newUserPhoneNumber),callback:function ($$v) {_vm.newUserPhoneNumber=$$v},expression:"newUserPhoneNumber"}})]}}],null,true)}),_c('p',{staticClass:"pt-8 purple--text"},[_vm._v(" Please confirm correctness of critical data by typing it again ")]),_c('validation-provider',{attrs:{"rules":"required|confirmed:newUserUserName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Confirm Nick Name (Logging ID)"},model:{value:(_vm.newUserUserNameConfirm),callback:function ($$v) {_vm.newUserUserNameConfirm=$$v},expression:"newUserUserNameConfirm"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|confirmed:newUserEMail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Confirm eMail","error-messages":errors},model:{value:(_vm.eMailConfirm),callback:function ($$v) {_vm.eMailConfirm=$$v},expression:"eMailConfirm"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|confirmed:newUserPhoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Confirm Phone Number"},model:{value:(_vm.userPhoneConfirm),callback:function ($$v) {_vm.userPhoneConfirm=$$v},expression:"userPhoneConfirm"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.newUserClose}},[_vm._v("Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":invalidToAddNew,"text":""},on:{"click":_vm.createNewUser}},[_vm._v("Add ")])],1)],1)],1)]}}])}),_c('validation-observer',{ref:"observerTransfer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var invalidToTransfer = ref.invalid;
return [_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogTransfer),callback:function ($$v) {_vm.dialogTransfer=$$v},expression:"dialogTransfer"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-4"},[_c('span',[_vm._v(_vm._s(_vm.transferToUserName))])]),_c('v-card-subtitle',[_c('span',[_vm._v("PlusPoints in Stock: "+_vm._s(_vm.numeral(_vm.currentPoints).format("0,0")))])]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',{staticClass:"pt-4"},[_c('validation-provider',{attrs:{"name":"PlusPoints","vid":"editedTransferConfirm","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autofocus":"","error-messages":errors,"label":"PlusPoints to Transfer"},on:{"blur":_vm.checkCurrentPoints},model:{value:(_vm.editedTransfer),callback:function ($$v) {_vm.editedTransfer=$$v},expression:"editedTransfer"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|confirmed:editedTransferConfirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Confirm number of PlusPoints to transfer"},model:{value:(_vm.editedTransferConfirm),callback:function ($$v) {_vm.editedTransferConfirm=$$v},expression:"editedTransferConfirm"}})]}}],null,true)})],1),_c('v-overlay',{attrs:{"value":_vm.alertNoTransfer,"opacity":"0.8"}},[_c('v-alert',{attrs:{"value":_vm.alertNoTransfer,"color":"red","dismissible":"","elevation":"7","type":"error","width":"350"},on:{"input":_vm.closeAlert}},[_vm._v("The transfer didn't go throughout, please try again later ")])],1),_c('v-overlay',{attrs:{"value":_vm.successTransfer,"opacity":"0.5"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success"},on:{"click":_vm.closeSuccessTransfer}},[_vm._v(" Transfer was successful "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-close-circle ")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeTransfer}},[_vm._v("Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalidToTransfer},on:{"click":_vm.executeTransfer}},[_vm._v("Transfer ")])],1)],1)],1)]}}])}),_c('validation-observer',{ref:"observerEdit",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var invalidToEdit = ref.invalid;
return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogEditName),callback:function ($$v) {_vm.dialogEditName=$$v},expression:"dialogEditName"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Edit Full Name")])]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',{staticClass:"pt-6"},[_c('validation-provider',{attrs:{"name":"Full Name","rules":"required|alpha_spaces|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autofocus":"","error-messages":errors,"label":"Full Name"},model:{value:(_vm.editedFullName),callback:function ($$v) {_vm.editedFullName=$$v},expression:"editedFullName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"eMail","rules":"required|email|min:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autofocus":"","error-messages":errors,"label":"eMail"},model:{value:(_vm.editedEMail),callback:function ($$v) {_vm.editedEMail=$$v},expression:"editedEMail"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Phone Number","rules":{
                        required: true,
                        min: 6,
                        regex: "^\\+\\d(\\d|\\s|\\(|\\)|\\-)+$",
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autofocus":"","error-messages":errors,"label":"Phone Number"},model:{value:(_vm.editedPhoneNumber),callback:function ($$v) {_vm.editedPhoneNumber=$$v},expression:"editedPhoneNumber"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeEdit}},[_vm._v("Cancel ")]),_c('v-btn',{attrs:{"disabled":invalidToEdit,"color":"blue darken-1","text":""},on:{"click":_vm.saveEdit}},[_vm._v("Save ")])],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 purple--text"},[_vm._v(_vm._s(_vm.editedItem.type)+" Permanent Removal ")]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-subtitle',{staticClass:"pt-7 text-subtitle-1 font-weight-bold"},[_vm._v("Are you ABSOLUTELY sure you want to delete the "+_vm._s(_vm.editedItem.type)+": "+_vm._s(_vm.editedItem.fullName)+"? ")]),_c('v-card-text',{staticClass:"font-weight-black red--text text-uppercase pt-6"},[_vm._v("Warning: This action cannot be undone: Any PlusPoints will be deleted permanently. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("DELETE "+_vm._s(_vm.editedItem.type)+" ")])],1)],1)],1)],1),_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":"Search by name"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.points",fn:function(ref){
                      var item = ref.item;
return [(item.points < _vm.showColor(item.teamSize))?[_c('v-chip',{attrs:{"color":_vm.getColor(parseInt(item.points), parseInt(item.teamSize)),"small":"","dark":"","outlined":!!!item.points}},[_vm._v(" "+_vm._s(_vm.numeral(item.points).format("0,0"))+" ")])]:[_vm._v(" "+_vm._s(_vm.numeral(item.points).format("0,0"))+" ")]]}},{key:"item.lastTransfer",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(item.lastTransfer).format("0,0"))+" ")]}},{key:"item.lastTransferDate",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(parseInt(item.lastTransferDate * 1000)).fromNow())+" ")]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"orange"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pr-6",attrs:{"color":"orange"},on:{"click":function($event){return _vm.transferTo(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-transfer ")])]}}],null,true)},[_c('span',[_vm._v("Transfer PlusPoints to "+_vm._s(item.fullName))])]),_c('v-tooltip',{attrs:{"top":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pr-6",attrs:{"color":"purple lighten-2"},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-account-edit ")])]}}],null,true)},[_c('span',[_vm._v("Edit "+_vm._s(item.fullName)+"'s Name")])]),_c('v-tooltip',{attrs:{"top":"","color":!!item.welcomeReceived ? 'black' : 'DarkGrey'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [(!!item.welcomeReceived)?_c('v-icon',_vm._g(_vm._b({staticClass:"pr-6",attrs:{"color":"black"},on:{"click":function($event){return _vm.sendQRCode(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-qrcode-scan ")]):_c('v-icon',_vm._g(_vm._b({staticClass:"pr-6",attrs:{"color":!!item.welcomeSent ? 'yellow' : 'grey'},on:{"click":function($event){return _vm.sendQRCode(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-qrcode ")])]}}],null,true)},[(!!!item.welcomeReceived && !!!item.welcomeSent)?_c('span',[_vm._v("Send welcome kit and activation QRCode to "+_vm._s(item.fullName))]):_vm._e(),(!!item.welcomeSent && !!!item.welcomeReceived)?_c('span',[_vm._v("Welcome Kit eMail sent to "+_vm._s(item.fullName)+" "+_vm._s(_vm.moment(item.welcomeSent).fromNow()))]):_vm._e(),(!!item.welcomeReceived)?_c('span',[_vm._v(_vm._s(item.fullName)+" received welcome eMail kit "+_vm._s(_vm.moment(item.welcomeReceived).fromNow()))]):_vm._e()]),_c('v-tooltip',{attrs:{"top":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [(!!item.LDActive)?_c('v-icon',_vm._g(_vm._b({staticClass:"pr-6",attrs:{"color":"green"},on:{"click":function($event){return _vm.deactivate(item._id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-lock-open-variant-outline ")]):_c('v-icon',_vm._g(_vm._b({staticClass:"pr-6",attrs:{"color":"red"},on:{"click":function($event){return _vm.deactivate(item._id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-lock-alert ")])]}}],null,true)},[_c('span',[_vm._v("Control "+_vm._s(item.fullName)+"'s LD+ app access")])])]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('p',{staticClass:"font-italic text-caption"},[_vm._v(" NOTES: "),_c('br'),_vm._v(" Total Team Size includes Team Leader."),_c('br'),_c('span',{staticClass:"red--text"},[_vm._v("RED")]),_vm._v(" = PlusPoints are only enough for less than two days of operation. "),_c('br'),_c('span',{staticClass:"orange--text"},[_vm._v("ORANGE")]),_vm._v(" = PlusPoints are only enough for less than five days of operation. ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }