<template>
  <v-app>
    <v-app-bar v-if="loggedIn" app clipped-left color="#200040">
      <div class="d-flex align-center">
        <v-img
          alt="L!vePlus Logo"
          contain
          src="@/assets/LivePlus_W_SM.png"
          transition="scale-transition"
          width="160"
        />
      </div>
      <span class="text-caption pl-4">Beta 1.02</span>
      <v-spacer></v-spacer>
      <v-btn small rounded @click="logout">
        <span>Logout</span>
        <v-icon right>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-if="loggedIn"
      app
      touchless
      disable-resize-watcher
      :expand-on-hover="true"
      clipped
      permanent
      dark
      color="#201048"
      width="256"
      transition="fade-transition"
      @update:mini-variant="gotInput"
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar color="orange" class="darken-1">
            <span class="white--text text-h6">{{
              this.$store.state.user.avatar
            }}</span>
            <!--            <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>-->
          </v-list-item-avatar>
          <div>
            <v-list-item-title
              class="text-h6 pb-1 d-inline-block text-truncate"
              style="max-width: 185px"
            >
              {{ this.$store.state.user.fullName }}
            </v-list-item-title>
            <v-divider></v-divider>
            <v-list-item-subtitle
              >{{ this.$store.state.user.type }}
            </v-list-item-subtitle>
          </div>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav>
        <v-list-item
          v-for="(item, index) in menuItems[this.$store.state.user.type]"
          :key="index"
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>mdi-{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="pl-9 pr-9">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { authComputed } from "@/store/helpers";
export default {
  name: "App",
  data: () => ({
    menuItems: {
      "Master Dealer": [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "view-dashboard-outline",
        },
        {
          name: "Dealer's Status",
          link: "/status",
          icon: "view-list",
        },
        {
          name: "Team Management",
          link: "/transfer",
          icon: "account-group",
        },
        {
          name: "PlusPoints Calculator",
          link: "/calculator",
          icon: "calculator",
        },
        {
          name: "Messages",
          link: "/messages",
          icon: "message-outline",
        },
        {
          name: "Products",
          link: "/products",
          icon: "shopping-outline",
        },
        {
          name: "Marketing Material",
          link: "/marketing",
          icon: "hand-heart-outline",
        },
        {
          name: "FAQ",
          link: "/faq",
          icon: "head-snowflake-outline",
        },
      ],
      Platform: [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "view-dashboard-outline",
        },
        {
          name: "Master Dealer's Status",
          link: "/status",
          icon: "view-list",
        },
        {
          name: "Team Management",
          link: "/transfer",
          icon: "account-group",
        },
        {
          name: "PlusPoints Calculator",
          link: "/calculator",
          icon: "calculator",
        },
        {
          name: "Messages",
          link: "/messages",
          icon: "message-outline",
        },
        {
          name: "Products",
          link: "/products",
          icon: "shopping-outline",
        },
        {
          name: "Marketing Material",
          link: "/marketing",
          icon: "hand-heart-outline",
        },
        {
          name: "FAQ",
          link: "/faq",
          icon: "head-snowflake-outline",
        },
      ],
      Dealer: [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "view-dashboard-outline",
        },
        {
          name: "Agent's Status",
          link: "/status",
          icon: "view-list",
        },
        {
          name: "Team Management",
          link: "/transfer",
          icon: "account-group",
        },
        {
          name: "Products",
          link: "/products",
          icon: "shopping-outline",
        },
        {
          name: "Marketing Material",
          link: "/marketing",
          icon: "hand-heart-outline",
        },
        {
          name: "FAQ",
          link: "/faq",
          icon: "head-snowflake-outline",
        },
      ],
      Agent: [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "view-dashboard-outline",
        },
        {
          name: "Retailer's Status",
          link: "/status",
          icon: "view-list",
        },
        {
          name: "Team Management",
          link: "/transfer",
          icon: "account-group",
        },
        {
          name: "Marketing Material",
          link: "/marketing",
          icon: "hand-heart-outline",
        },
        {
          name: "FAQ",
          link: "/faq",
          icon: "head-snowflake-outline",
        },
      ],
      Retailer: [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "view-dashboard-outline",
        },
        {
          name: "Marketing Material",
          link: "/marketing",
          icon: "hand-heart-outline",
        },
        {
          name: "FAQ",
          link: "/faq",
          icon: "head-snowflake-outline",
        },
      ],
    },
  }),
  computed: {
    ...authComputed,
  },
  methods: {
    gotInput() {
      console.log("INPUT");
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
