<template>
  <v-card
    class="mx-auto"
    max-width="500"
    min-width="350"
    elevation="4"
    shaped
    outlined
  >
    <v-card-title class="font-weight-bold grey--text">
      {{ title }}
      <v-spacer></v-spacer>
      <span v-if="!!dayGrowth" class="text-caption"
        >Daily Growth: {{ dayGrowth }}%</span
      >
    </v-card-title>
    <v-card-text>
      <v-row align="center">
        <v-col class="text-h3 text-center" cols="12">
          {{ count !== "NaN" ? count : 0 }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CountCard",
  props: ["title", "count", "dayGrowth"],
};
</script>

<style scoped></style>
