var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-row',{staticClass:"text-left"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pt-8 pl-3"},[_c('h1',[_vm._v("Sales")]),_c('p',{staticClass:"text-caption gray--text font-italic pl-2 pt-2"},[_vm._v(" ( Philippines Time ) ")])]),_c('v-row',{staticClass:"pb-5 pl-3"},[(!_vm.connectedToInternet)?_c('p',{staticClass:"text-body-2 red--text font-weight-bold"},[_vm._v(" WARNING: Sales Data Disconnected on: "+_vm._s(new Date().toDateString())+", "+_vm._s(new Date().toTimeString())+" ")]):_c('p',{staticClass:"text-body-2"},[_vm._v(" Updated at: "+_vm._s(new Date().toDateString())+", "+_vm._s(new Date().toTimeString())+" ")]),(!_vm.connectedToInternet)?_c('v-progress-linear',{attrs:{"color":"orange lighten-4","indeterminate":"","rounded":"","height":"6"}}):_vm._e()],1),_c('v-divider')],1)],1),(_vm.showZones && !!_vm.sales)?[_c('v-sheet',{attrs:{"elevation":"5"}},[_c('v-tabs',{attrs:{"dark":"","center-active":"","background-color":"deep-purple lighten-1","slider-color":"orange"}},[_c('v-tab',{on:{"click":function($event){return _vm.setZone('ALL')}}},[_vm._v("All Zones")]),_vm._l((_vm.zones),function(zone){return _c('v-tab',{key:zone,on:{"click":function($event){return _vm.setZone(zone)}}},[_vm._v(_vm._s(zone)+" ")])})],2)],1)]:_vm._e(),[(!!_vm.sales)?_c('v-row',{staticClass:"pt-8"},_vm._l((_vm.sales),function(stat,index){return _c('v-col',{key:index},[_c('salesCard',{attrs:{"stat":stat}})],1)}),1):_vm._e(),_c('v-row',{staticClass:"text-left pt-10"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Team")]),_c('v-divider')],1)],1),_c('v-row',[(!!_vm.total)?_c('v-col',[_c('countCard',{attrs:{"title":"Team Size","count":_vm.total.count,"day-growth":_vm.total.dayGrowth}})],1):_vm._e(),(
            !!_vm.dealers &&
            (this.userID.type === 'Platform' ||
              this.userID.type === 'Master Dealer')
          )?_c('v-col',[_c('countCard',{attrs:{"title":"Dealers","count":_vm.dealers.count,"day-growth":_vm.dealers.dayGrowth}})],1):_vm._e(),(
            !!_vm.agents &&
            (this.userID.type === 'Platform' ||
              this.userID.type === 'Master Dealer' ||
              this.userID.type === 'Dealer')
          )?_c('v-col',[_c('countCard',{attrs:{"title":"Agents","count":_vm.agents.count,"day-growth":_vm.agents.dayGrowth}})],1):_vm._e(),(
            !!_vm.retailers &&
            (this.userID.type === 'Platform' ||
              this.userID.type === 'Master Dealer' ||
              this.userID.type === 'Dealer' ||
              this.userID.type === 'Agent')
          )?_c('v-col',[_c('countCard',{attrs:{"title":"Retailers","count":_vm.retailers.count,"day-growth":_vm.retailers.dayGrowth}})],1):_vm._e()],1),_c('v-row',{staticClass:"text-left pt-10"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Market")]),_c('v-divider')],1)],1),_c('v-row',[(!!_vm.users.count)?_c('v-col',[_c('countCard',{attrs:{"title":"Active Subscriptions","count":("" + (_vm.numeral(_vm.users.count).format('0,0'))),"day-growth":_vm.users.dayGrowth}})],1):_vm._e(),(!!_vm.addressableMarket.count)?_c('v-col',[_c('countCard',{attrs:{"title":"Active Market","count":("" + (_vm.numeral(_vm.addressableMarket.count).format('0,0'))),"day-growth":_vm.addressableMarket.dayGrowth}})],1):_vm._e(),(!!_vm.addressableMarket.count && !!_vm.users.count)?_c('v-col',[_c('countCard',{attrs:{"title":"Active Market Penetration","count":((_vm.numeral(
              (this.users.count / this.addressableMarket.count) * 100
            ).format('0,0.00')) + "%"),"day-growth":0}})],1):_vm._e()],1),(!!_vm.points)?_c('v-row',{staticClass:"text-left pt-10"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("PlusPoints In Stock")]),(
              this.userID.type === 'Master Dealer' ||
              this.userID.type === 'Platform'
            )?_c('h4',{staticClass:"grey--text"},[_vm._v(" (1 PlusPoint = ₱"+_vm._s(_vm.numeral(_vm.PlusPointsPHPCurrency).format("0,0.000"))+") * ")]):_vm._e(),_c('v-divider')],1)],1):_vm._e(),_c('v-row',[(!!_vm.points)?_c('v-col',[_c('countCard',{attrs:{"title":"Total Available","count":("" + (_vm.numeral(this.points.count).format('0,0'))),"day-growth":_vm.points.dayGrowth}})],1):_vm._e(),(!!_vm.points)?_c('v-col',[_c('countCard',{attrs:{"title":("Subs Volume (sub:" + _vm.ProductValue + ")"),"count":("" + (_vm.numeral(
              Math.floor(this.points.count / this.ProductValue)
            ).format('0,0'))),"day-growth":_vm.points.dayGrowth}})],1):_vm._e(),(!!_vm.points)?_c('v-col',[_c('countCard',{attrs:{"title":"Member Subs Volume","count":("" + (_vm.numeral(
              Math.floor(this.points.count / this.ProductValue / _vm.total.count)
            ).format('0,0'))),"day-growth":_vm.points.dayGrowth}})],1):_vm._e(),(!!_vm.points)?_c('v-col',[_c('countCard',{attrs:{"title":"Subs/Member Days Operation","count":("" + (_vm.numeral(
              this.points.count /
                this.ProductValue /
                _vm.total.count /
                ((25 + 40) / 2)
            ).format('0,0'))),"day-growth":_vm.points.dayGrowth}})],1):_vm._e(),(!!_vm.points)?_c('v-col',[_c('countCard',{attrs:{"title":"PlusPoints per Subscription","count":("" + (_vm.numeral(this.ProductValue).format('0,0'))),"day-growth":_vm.points.dayGrowth}})],1):_vm._e(),(
            !!_vm.points &&
            (this.userID.type === 'Master Dealer' ||
              this.userID.type === 'Platform')
          )?_c('v-col',[_c('countCard',{attrs:{"title":"Subscription in PHPs *","count":("₱" + (_vm.numeral(this.PackagePHP100CH).format('0,0.00'))),"day-growth":_vm.points.dayGrowth}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('p',{staticClass:"font-italic text-caption pt-6"},[_vm._v(" NOTES: "),_c('br'),_vm._v(" * Global Currency data is updated as available every 15min. (3rd party terms of services apply) ")])])],1)]]],2)}
var staticRenderFns = []

export { render, staticRenderFns }