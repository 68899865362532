<template>
  <v-container>
    <v-row class="text-left">
      <v-col cols="12">
        <h1>{{ teamTitle }} Status</h1>

        <v-divider></v-divider>
        <!--        Header's Table is {{ this.userID.type }}'s {{this.userID.TZ}} timezone based -->
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <p
        v-if="!connectedToInternet"
        class="text-body-2 red--text font-weight-bold"
      >
        WARNING: Sales Data Disconnected on:
        {{ new Date().toDateString() }}, {{ new Date().toTimeString() }}
      </p>
      <p v-else class="text-body-2">
        Updated on: {{ new Date().toDateString() }},
        {{ new Date().toTimeString() }}
      </p>
    </v-row>
    <v-row class="text-left">
      <v-col cols="12">
        <v-data-table
          dense
          item-key="_id"
          class="elevation-3"
          :headers="headers"
          :items="statusList"
          :items-per-page="-1"
          :search="search"
          :custom-filter="filterOnlyLowCapsText"
          :loading="!connectedToInternet"
          loading-text="Connecting... Please wait"
          no-data-text="No Team Members yet, add members on the Team Management page"
        >
          <template v-slot:item.points="{ item }">
            <template v-if="item.points < showColor(item.teamSize)">
              <v-chip
                :color="
                  getColor(parseInt(item.points), parseInt(item.teamSize))
                "
                small
                dark
                :outlined="!!!item.points"
              >
                {{ numeral(item.points).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.points).format("0,0") }}
            </template>
          </template>

          <template v-slot:item.hour="{ item }">
            <template v-if="item.hour === hourMax">
              <v-chip color="green" small dark>
                {{ numeral(item.hour).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.hour).format("0,0") }}
            </template>
          </template>

          <template v-slot:item.day="{ item }">
            <template v-if="item.day === dayMax">
              <v-chip color="green" small dark>
                {{ numeral(item.day).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.day).format("0,0") }}
            </template>
          </template>

          <template v-slot:item.week="{ item }">
            <template v-if="item.week === weekMax">
              <v-chip color="green" small dark>
                {{ numeral(item.week).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.week).format("0,0") }}
            </template>
          </template>

          <template v-slot:item.month="{ item }">
            <template v-if="item.month === monthMax">
              <v-chip color="green" small dark>
                {{ numeral(item.month).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.month).format("0,0") }}
            </template>
          </template>

          <template v-slot:item.quarter="{ item }">
            <template v-if="item.quarter === quarterMax">
              <v-chip color="green" small dark>
                {{ numeral(item.quarter).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.quarter).format("0,0") }}
            </template>
          </template>

          <template v-slot:item.year="{ item }">
            <template v-if="item.year === yearMax">
              <v-chip color="green" small dark>
                {{ numeral(item.year).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.year).format("0,0") }}
            </template>
          </template>

          <template v-slot:item.total="{ item }">
            <template v-if="item.total === totalMax">
              <v-chip color="green" small dark>
                {{ numeral(item.total).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.total).format("0,0") }}
            </template>
          </template>

          <template v-slot:top>
            <!--            <v-toolbar flat>
                          <v-toolbar-title>Subscriptions Activity</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                        </v-toolbar>-->
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search by name"
              class="mx-4 pt-6"
            ></v-text-field>
          </template>

          <template v-slot:body.append>
            <tr>
              <td></td>
              <td></td>
              <td>
                <v-text-field
                  v-model="points"
                  type="number"
                  label="Less than"
                ></v-text-field>
              </td>
              <td colspan="2"></td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!--
    <v-row class="text-left">
      <v-col cols="12">
        <v-data-table
          item-key="_id"
          class="elevation-3"
          :headers="headers"
          :items="statusList"
          :search="search"
          :custom-filter="filterOnlyLowCapsText"
          :loading="!connectedToInternet"
          loading-text="Connecting... Please wait"
        >
          <template v-slot:item.points="{ item }">
            <template v-if="item.points < 50000">
              <v-chip :color="getColor(parseInt(item.points))" small dark>
                {{ numeral(item.points).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.points).format("0,0") }}
            </template>
          </template>

          <template v-slot:item.day="{ item }">
            <template v-if="item.day === dayMax">
              <v-chip color="green" small dark>
                {{ numeral(item.day).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.day).format("0,0") }}
            </template>
          </template>

          <template v-slot:item.week="{ item }">
            <template v-if="item.week === weekMax">
              <v-chip color="green" small dark>
                {{ numeral(item.week).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.week).format("0,0") }}
            </template>
          </template>

          <template v-slot:item.month="{ item }">
            <template v-if="item.month === monthMax">
              <v-chip color="green" small dark>
                {{ numeral(item.month).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.month).format("0,0") }}
            </template>
          </template>

          <template v-slot:item.year="{ item }">
            <template v-if="item.year === yearMax">
              <v-chip color="green" small dark>
                {{ numeral(item.year).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.year).format("0,0") }}
            </template>
          </template>

          <template v-slot:item.total="{ item }">
            <template v-if="item.total === totalMax">
              <v-chip color="green" small dark>
                {{ numeral(item.total).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.total).format("0,0") }}
            </template>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Subscriptions</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search Dealer (LOWER CASE ONLY)"
              class="mx-4"
            ></v-text-field>
          </template>

          <template v-slot:body.append>
            <tr>
              <td></td>
              <td>
                <v-text-field
                  v-model="points"
                  type="number"
                  label="Less than"
                ></v-text-field>
              </td>
              <td colspan="3"></td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
-->

    <v-row>
      <v-col>
        <p class="font-italic text-caption">
          NOTES: <br />
          <span class="red--text">RED</span> = PlusPoints are only enough for
          less than two days of operation. <br />
          <span class="orange--text">ORANGE</span> = PlusPoints are only enough
          for less than five days of operation. <br />
          Table rows are shown on the individual leader's timezone data and
          might mismatch your local time header's table titles.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getUserID, connectedToInternet } from "@/store/helpers";
import RESTService from "@/services/RESTServices";
import moment from "moment";
//TODO http://www.google.com/maps/place/37.4266795,-122.1409478
export default {
  name: "Status",
  data: () => ({
    statusList: [],
    interval: null,
    search: "",
    points: "",
    editedIndex: -1,
    editedItem: { name: "" },
    defaultItem: { name: "" },
    hourMax: 1,
    dayMax: 1,
    weekMax: 1,
    monthMax: 1,
    quarterMax: 1,
    yearMax: 1,
    totalMax: 1,
  }),
  computed: {
    ...getUserID,
    ...connectedToInternet,
    teamTitle() {
      if (this.userID.type === "Platform") return "Master Dealer's";
      if (this.userID.type === "Master Dealer") return "Dealer's";
      return "Agent's";
    },
    headers() {
      return [
        {
          text: `${this.teamTitle} Name`,
          align: "end",
          sortable: true,
          value: "name",
        },
        { text: "Team Size", align: "center", value: "teamSize" },
        {
          text: "PlusPoints Stock",
          align: "center",
          value: "points",
          filter: (value) => {
            if (!this.points) return true;
            return value < parseInt(this.points);
          },
        },
        { text: "Last Hour", align: "center", value: "hour" },
        {
          text: `${new Date().toLocaleString("default", {
            weekday: "long",
          })} (${moment().dayOfYear()})`,
          align: "center",
          value: "day",
        },
        { text: `Week (${moment().week()})`, align: "center", value: "week" },
        {
          text: `${new Date().toLocaleString("default", { month: "long" })} (${
            new Date().getMonth() + 1
          })`,
          align: "center",
          value: "month",
        },
        {
          text: `Quarter (${moment().quarter()})`,
          align: "center",
          value: "quarter",
        },
        {
          text: `Year (${new Date().getFullYear()})`,
          align: "center",
          value: "year",
        },
        { text: "Total ", align: "center", value: "total" },
      ];
    },
  },
  created() {
    this.loadData();
    this.interval = setInterval(() => {
      this.loadData();
    }, 5000); //30 sec
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    loadData() {
      RESTService.getStatus(this.userID._id).then(({ data }) => {
        for (let r in data) {
          if (data[r].hour > this.hourMax) this.hourMax = data[r].hour;
          if (data[r].day > this.dayMax) this.dayMax = data[r].day;
          if (data[r].week > this.weekMax) this.weekMax = data[r].week;
          if (data[r].month > this.monthMax) this.monthMax = data[r].month;
          if (data[r].quarter > this.quarterMax)
            this.quarterMax = data[r].quarter;
          if (data[r].year > this.yearMax) this.yearMax = data[r].year;
          if (data[r].total > this.totalMax) this.totalMax = data[r].total;
        }
        this.statusList = data;
      });
    },
    showColor(teamSize) {
      const minPointsPerDay = 149.5 * 0.02 * 100 * teamSize * ((25 + 40) / 2);
      return minPointsPerDay * 5;
    },
    getColor(points, teamSize) {
      const minPointsPerDay = 149.5 * 0.02 * 100 * teamSize * ((25 + 40) / 2); //149.50 in USD * 100 = points
      if (points < minPointsPerDay * 2) return "red";
      else if (points <= minPointsPerDay * 5) return "orange";
      else return "grey";
    },
    filterOnlyLowCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search.toLowerCase()) !==
          -1
      );
    },
  },
};
</script>
