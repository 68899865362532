<template>
  <v-col>
    <v-card class="mx-auto" width="320" elevation="5">
      <template v-if="media.search('.mp4') !== -1">
        <video width="320" height="180" autoplay loop muted>
          <source :src="media" type="video/mp4" />
        </video>
      </template>
      <template v-else>
        <v-img max-height="180" max-width="320" :src="media"></v-img>
      </template>

      <v-card-title class="font-weight-black">{{ title }}</v-card-title>
      <v-card-subtitle class="text-subtitle-1 font-weight-black">
        PlusPoints: {{ points }}
      </v-card-subtitle>
      <v-card-text>
        {{ terms }}
      </v-card-text>
      <template v-if="sold">
        <v-divider></v-divider>
        <v-card-title class="font-weight-bold"
          >Total Pieces Sold: {{ sold }}
        </v-card-title>
        <v-divider></v-divider>
      </template>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <span class="text-caption">Channel List ({{ channelsCount }})</span>
        <v-btn icon @click="show = !show">
          <v-icon color="primary"
            >{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>

          <v-card-text>
            {{ channels }}
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "ProductAsset",
  props: {
    media: String,
    title: String,
    terms: String,
    sold: Number,
    points: Number,
    channels: String,
    channelsCount: Number,
  },
  data: () => ({
    show: false,
  }),
};
</script>

<style scoped></style>
