<template>
  <v-container>
    <v-row class="text-left">
      <v-col cols="12">
        <h1>Team Management & PlusPoints Transfers</h1>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="text-left">
      <v-col cols="12">
        <v-overlay :absolute="absolute" :value="eMailOverlay">
          <v-btn color="success" class="ma-2" @click="eMailOverlay = false">
            Welcome eMail Sent to {{ eMailSentTo }}
            <v-icon dark right> mdi-close-circle </v-icon>
          </v-btn>
        </v-overlay>

        <v-data-table
          item-key="_id"
          class="elevation-3"
          :headers="headers"
          :items="dealersList"
          sort-by="fullName"
          :search="search"
          :custom-filter="filterOnlyCapsText"
          :loading="!connectedToInternet"
          no-data-text="No Team Members yet, add members using the Blue Button adobe"
          loading-text="Connecting... Please wait"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Active Team</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="primary"
                dark
                class="mb-2"
                @click="newUserDialog"
              >
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>

              <!--New User Dialog-->
              <validation-observer
                ref="observerNew"
                v-slot="{ invalid: invalidToAddNew }"
              >
                <v-dialog v-model="dialogNew" max-width="600px">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Add Team Member</span>
                    </v-card-title>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-text class="pt-6">
                      <!--userName-->
                      <validation-provider
                        v-slot="{ errors }"
                        name="Nick Name"
                        vid="newUserUserName"
                        rules="required|alpha_spaces|min:3"
                      >
                        <v-text-field
                          v-model="newUserName"
                          :autofocus="true"
                          :error-messages="errors"
                          label="Nick Name ( note:This will become the Logging ID )"
                          @blur="checkUserNameIsTaken"
                        ></v-text-field>
                      </validation-provider>

                      <!--fullName-->
                      <validation-provider
                        v-slot="{ errors }"
                        name="Full Name"
                        vid="newUserFullName"
                        rules="required|alpha_spaces|min:6"
                      >
                        <v-text-field
                          v-model="newUserFullName"
                          :error-messages="errors"
                          label="Full Name"
                        ></v-text-field>
                      </validation-provider>

                      <!--eMail-->
                      <validation-provider
                        v-slot="{ errors }"
                        name="eMail"
                        vid="newUserEMail"
                        rules="required|email|min:7"
                      >
                        <v-text-field
                          v-model="newUserEMail"
                          :error-messages="errors"
                          label="eMail"
                        ></v-text-field>
                      </validation-provider>

                      <!--Phone Number-->
                      <validation-provider
                        v-slot="{ errors }"
                        name="Phone Number"
                        vid="newUserPhoneNumber"
                        :rules="{
                          required: true,
                          min: 6,
                          regex: `^\\+\\d(\\d|\\s|\\(|\\)|\\-)+$`,
                        }"
                      >
                        <v-text-field
                          v-model="newUserPhoneNumber"
                          :error-messages="errors"
                          label="Phone Number i.e. +63(222)333-4444 or +16509968374"
                        ></v-text-field>
                      </validation-provider>

                      <p class="pt-8 purple--text">
                        Please confirm correctness of critical data by typing it
                        again
                      </p>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|confirmed:newUserUserName"
                      >
                        <v-text-field
                          v-model="newUserUserNameConfirm"
                          :error-messages="errors"
                          label="Confirm Nick Name (Logging ID)"
                        ></v-text-field>
                      </validation-provider>

                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|confirmed:newUserEMail"
                      >
                        <v-text-field
                          v-model="eMailConfirm"
                          label="Confirm eMail"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>

                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|confirmed:newUserPhoneNumber"
                      >
                        <v-text-field
                          v-model="userPhoneConfirm"
                          :error-messages="errors"
                          label="Confirm Phone Number"
                        ></v-text-field>
                      </validation-provider>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="newUserClose"
                        >Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        :disabled="invalidToAddNew"
                        text
                        @click="createNewUser"
                        >Add
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </validation-observer>

              <!--Transfer Dialog-->
              <validation-observer
                ref="observerTransfer"
                v-slot="{ invalid: invalidToTransfer }"
              >
                <v-dialog v-model="dialogTransfer" max-width="600px">
                  <v-card>
                    <v-card-title class="pb-4">
                      <span>{{ transferToUserName }}</span>
                    </v-card-title>
                    <v-card-subtitle>
                      <span
                        >PlusPoints in Stock:
                        {{ numeral(currentPoints).format("0,0") }}</span
                      >
                    </v-card-subtitle>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-text class="pt-4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="PlusPoints"
                        vid="editedTransferConfirm"
                        rules="required|numeric"
                      >
                        <v-text-field
                          v-model="editedTransfer"
                          autofocus
                          :error-messages="errors"
                          label="PlusPoints to Transfer"
                          @blur="checkCurrentPoints"
                        ></v-text-field>
                      </validation-provider>

                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|confirmed:editedTransferConfirm"
                      >
                        <v-text-field
                          v-model="editedTransferConfirm"
                          :error-messages="errors"
                          label="Confirm number of PlusPoints to transfer"
                        ></v-text-field>
                      </validation-provider>
                    </v-card-text>

                    <v-overlay :value="alertNoTransfer" opacity="0.8">
                      <v-alert
                        :value="alertNoTransfer"
                        color="red"
                        dismissible
                        elevation="7"
                        type="error"
                        width="350"
                        @input="closeAlert"
                        >The transfer didn't go throughout, please try again
                        later
                      </v-alert>
                    </v-overlay>

                    <v-overlay :value="successTransfer" opacity="0.5">
                      <v-btn
                        color="success"
                        class="ma-2"
                        @click="closeSuccessTransfer"
                      >
                        Transfer was successful
                        <v-icon dark right> mdi-close-circle </v-icon>
                      </v-btn>
                    </v-overlay>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeTransfer"
                        >Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="invalidToTransfer"
                        @click="executeTransfer"
                        >Transfer
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </validation-observer>

              <!--EditUser Dialog-->
              <validation-observer
                ref="observerEdit"
                v-slot="{ invalid: invalidToEdit }"
              >
                <v-dialog v-model="dialogEditName" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Edit Full Name</span>
                    </v-card-title>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-text class="pt-6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Full Name"
                        rules="required|alpha_spaces|min:6"
                      >
                        <v-text-field
                          v-model="editedFullName"
                          autofocus
                          :error-messages="errors"
                          label="Full Name"
                        ></v-text-field>
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        name="eMail"
                        rules="required|email|min:7"
                      >
                        <v-text-field
                          v-model="editedEMail"
                          autofocus
                          :error-messages="errors"
                          label="eMail"
                        ></v-text-field>
                      </validation-provider>

                      <validation-provider
                        v-slot="{ errors }"
                        name="Phone Number"
                        :rules="{
                          required: true,
                          min: 6,
                          regex: `^\\+\\d(\\d|\\s|\\(|\\)|\\-)+$`,
                        }"
                      >
                        <v-text-field
                          v-model="editedPhoneNumber"
                          autofocus
                          :error-messages="errors"
                          label="Phone Number"
                        ></v-text-field>
                      </validation-provider>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeEdit"
                        >Cancel
                      </v-btn>
                      <v-btn
                        :disabled="invalidToEdit"
                        color="blue darken-1"
                        text
                        @click="saveEdit"
                        >Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </validation-observer>

              <v-dialog v-model="dialogDelete" max-width="600px">
                <v-card>
                  <v-card-title class="text-h5 purple--text"
                    >{{ editedItem.type }} Permanent Removal
                  </v-card-title>
                  <v-divider class="mx-4"></v-divider>
                  <v-card-subtitle class="pt-7 text-subtitle-1 font-weight-bold"
                    >Are you ABSOLUTELY sure you want to delete the
                    {{ editedItem.type }}: {{ editedItem.fullName }}?
                  </v-card-subtitle>
                  <v-card-text
                    class="font-weight-black red--text text-uppercase pt-6"
                    >Warning: This action cannot be undone: Any PlusPoints will
                    be deleted permanently.
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel
                    </v-btn>
                    <v-btn color="red" text @click="deleteItemConfirm"
                      >DELETE {{ editedItem.type }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search by name"
              class="mx-4"
            ></v-text-field>
          </template>

          <template v-slot:item.points="{ item }">
            <template v-if="item.points < showColor(item.teamSize)">
              <v-chip
                :color="
                  getColor(parseInt(item.points), parseInt(item.teamSize))
                "
                small
                dark
                :outlined="!!!item.points"
              >
                {{ numeral(item.points).format("0,0") }}
              </v-chip>
            </template>
            <template v-else>
              {{ numeral(item.points).format("0,0") }}
            </template>
          </template>

          <template v-slot:item.lastTransfer="{ item }">
            {{ numeral(item.lastTransfer).format("0,0") }}
          </template>
          <!--          Replace moment by dayjs https://day.js.org/ or https://moment.github.io/luxon/#/?id=luxon-->
          <template v-slot:item.lastTransferDate="{ item }">
            {{ moment(parseInt(item.lastTransferDate * 1000)).fromNow() }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top color="orange">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="pr-6"
                  color="orange"
                  v-bind="attrs"
                  v-on="on"
                  @click="transferTo(item)"
                  >mdi-transfer
                </v-icon>
              </template>
              <span>Transfer PlusPoints to {{ item.fullName }}</span>
            </v-tooltip>

            <v-tooltip top color="purple">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="pr-6"
                  color="purple lighten-2"
                  v-bind="attrs"
                  @click="editItem(item)"
                  v-on="on"
                  >mdi-account-edit
                </v-icon>
              </template>
              <span>Edit {{ item.fullName }}'s Name</span>
            </v-tooltip>

            <v-tooltip
              top
              :color="!!item.welcomeReceived ? 'black' : 'DarkGrey'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="!!item.welcomeReceived"
                  class="pr-6"
                  color="black"
                  v-bind="attrs"
                  v-on="on"
                  @click="sendQRCode(item)"
                  >mdi-qrcode-scan
                </v-icon>
                <v-icon
                  v-else
                  class="pr-6"
                  :color="!!item.welcomeSent ? 'yellow' : 'grey'"
                  v-bind="attrs"
                  v-on="on"
                  @click="sendQRCode(item)"
                  >mdi-qrcode
                </v-icon>
              </template>
              <span v-if="!!!item.welcomeReceived && !!!item.welcomeSent"
                >Send welcome kit and activation QRCode to
                {{ item.fullName }}</span
              >
              <span v-if="!!item.welcomeSent && !!!item.welcomeReceived"
                >Welcome Kit eMail sent to {{ item.fullName }}
                {{ moment(item.welcomeSent).fromNow() }}</span
              >
              <span v-if="!!item.welcomeReceived"
                >{{ item.fullName }} received welcome eMail kit
                {{ moment(item.welcomeReceived).fromNow() }}</span
              >
            </v-tooltip>

            <v-tooltip top color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="!!item.LDActive"
                  class="pr-6"
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                  @click="deactivate(item._id)"
                  >mdi-lock-open-variant-outline
                </v-icon>
                <v-icon
                  v-else
                  class="pr-6"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="deactivate(item._id)"
                  >mdi-lock-alert
                </v-icon>
              </template>
              <span>Control {{ item.fullName }}'s LD+ app access</span>
            </v-tooltip>

            <!--            <v-tooltip top color="red" >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="red"
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteItem(item)"
                              >mdi-account-off
                            </v-icon>
                          </template>
                          <span>Delete {{ item.fullName }}</span>
                        </v-tooltip>-->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="font-italic text-caption">
          NOTES: <br />
          Total Team Size includes Team Leader.<br />
          <span class="red--text">RED</span> = PlusPoints are only enough for
          less than two days of operation. <br />
          <span class="orange--text">ORANGE</span> = PlusPoints are only enough
          for less than five days of operation.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getUserID, connectedToInternet } from "@/store/helpers";
import RESTService from "@/services/RESTServices";
import {
  required,
  email,
  confirmed,
  alpha_spaces,
  regex,
  min,
  numeric,
} from "vee-validate/dist/rules";

import {
  extend,
  ValidationProvider,
  setInteractionMode,
  ValidationObserver,
} from "vee-validate";

setInteractionMode("lazy");

extend("required", {
  ...required,
  message: "Cannot be empty",
});
extend("email", {
  ...email,
  message: "eMail seems not to be valid",
});
extend("confirmed", {
  ...confirmed,
  message: "Confirmation failed: Check both entries",
});
extend("alpha_spaces", {
  ...alpha_spaces,
  message: "Enter only alphabetic characters or spaces",
});
extend("regex", {
  ...regex,
  message:
    'Must start with "+CountryCode" followed by numbers and -() characters',
});
extend("min", {
  ...min,
  message: "{_field_} may not be less than {length} characters",
});
extend("numeric", {
  ...numeric,
  message: "Must be numbers",
});

export default {
  name: "Status",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    invalidToAddNew: true,
    invalidToTransfer: true,
    dealersList: [],
    interval: null,
    dialogNew: null,
    dialogEditName: null,
    dialogDelete: null,
    dialogTransfer: null,
    search: "",
    points: "",
    editedIndex: -1,
    editedTransfer: null,
    editedTransferConfirm: null,
    alertNoTransfer: null,
    successTransfer: null,
    editedItem: [],
    editedFullName: null,
    editedEMail: null,
    editedPhoneNumber: null,
    newUserName: null,
    newUserFullName: null,
    newUserEMail: null,
    newUserPhoneNumber: null,
    newUserUserNameConfirm: null,
    newUserPhoneConfirm: null,
    eMailConfirm: null,
    userPhoneConfirm: null,
    currentPoints: null,
    absolute: true,
    eMailOverlay: false,
    eMailSentTo: null,
  }),
  computed: {
    ...getUserID,
    ...connectedToInternet,
    transferToUserName() {
      return `Transfer PlusPoints to ${this.editedItem.type} ${this.editedItem.fullName}`;
    },
    headers() {
      return [
        { text: "Name", align: "start", sortable: true, value: "fullName" },
        {
          text: "Total Team Size",
          align: "center",
          sortable: true,
          value: "teamSize",
        },
        {
          text: "PlusPoints Stock",
          align: "center",
          value: "points",
          filter: (value) => {
            if (!this.points) return true;
            return value >= parseInt(this.points);
          },
        },
        { text: "Last Transfer", align: "center", value: "lastTransfer" },
        {
          text: "Last Date Transfer",
          align: "start",
          value: "lastTransferDate",
        },
        {
          text: "Actions",
          value: "actions",
          align: "end",
          sortable: false,
          width: "270px",
        },
      ];
    },
  },
  watch: {
    dialog(val) {
      val || this.closeEdit();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.loadData();
    this.interval = setInterval(() => {
      this.loadData();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    loadData() {
      RESTService.getInventory(this.userID._id).then(({ data }) => {
        //        console.dir(data)
        this.dealersList = data;
      });
    },
    showColor(teamSize) {
      const minPointsPerDay = 149.99 * 0.02 * 100 * teamSize * ((25 + 40) / 2);
      return minPointsPerDay * 5;
    },
    getColor(points, teamSize) {
      const minPointsPerDay = 149.99 * 0.02 * 100 * teamSize * ((25 + 40) / 2); //149.50 in USD * 100 = points
      if (points < minPointsPerDay * 2) return "red";
      else if (points <= minPointsPerDay * 5) return "orange";
      else return "grey";
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search.toLowerCase()) !==
          -1
      );
    },
    checkUserNameIsTaken() {
      RESTService.checkUniqueUser(this.newUserName).then(({ data }) => {
        if (data.taken)
          this.$refs.observerNew.setErrors({
            newUserUserName: [
              "This Nick Name is already taken, pick another one",
            ],
          });
      });
    },
    getCurrentPoints() {
      RESTService.checkCurrentPoints(this.userID._id).then(({ data }) => {
        this.currentPoints = data.points;
      });
    },
    checkCurrentPoints() {
      RESTService.checkCurrentPoints(this.userID._id).then(({ data }) => {
        this.currentPoints = data.points;
        if (this.currentPoints < parseInt(this.editedTransfer)) {
          this.$refs.observerTransfer.setErrors({
            editedTransferConfirm: [
              `Your have ${this.numeral(this.currentPoints).format(
                "0,0"
              )} PlusPoints on stock; No enough to make this transfer`,
            ],
          });
        }
      });
    },

    newUserDialog() {
      this.$refs.observerNew.reset();
      this.newUserName = null;
      this.newUserFullName = null;
      this.newUserEMail = null;
      this.newUserPhoneNumber = null;
      this.newUserUserNameConfirm = null;
      this.newUserPhoneConfirm = null;
      this.eMailConfirm = null;
      this.userPhoneConfirm = null;
      this.dialogNew = true;
    },
    newUserClose() {
      this.$refs.observerNew.reset();
      this.dialogNew = false;
    },
    createNewUser() {
      const newUser = {
        from: this.userID._id,
        TZ: this.userID.TZ,
        reportSubsTo: this.userID.reportSubsTo,
        organization: this.userID.organization,
        fullName: this.newUserFullName.replace(/  +/g, " ").trim(),
        userName: this.newUserName.replace(/  +/g, " ").trim().toLowerCase(),
        phone: this.newUserPhoneNumber.replace(/  +/g, " ").trim(),
        eMail: this.newUserEMail.trim(),
        type: this.$store.state.user.type,
      };

      console.log("TransferVue");
      console.dir(newUser);
      RESTService.postNewUser(newUser).then(({ data }) => {
        if (data.ok === true) {
          this.loadData();
          this.dialogNew = false;
          //this.successTransfer = true;
        } else {
          this.loadData();
          alert(
            "New Member no Added\nLive Server is outline\nPlease try Again later..."
          );
          this.dialogNew = false;
        }
      });
    },
    deactivate(item) {
      RESTService.patchActivateDeactivateMember(item).then(({ data }) => {
        if (data.ok !== true) {
          alert("Couldn't control user LD+ app now, please try again later...");
        }
        this.loadData();
      });
    },

    sendQRCode(item) {
      RESTService.postEMail(this.userID.fullName, item._id).then(({ data }) => {
        if (data.ok === true) {
          this.eMailSentTo = item.fullName;
          this.eMailOverlay = true;
          this.loadData();
        } else {
          alert("Couldn't sent eMail now, please try again later...");
        }
      });
    },

    transferTo(item) {
      this.alertNoTransfer = false;
      this.$refs.observerTransfer.reset();
      this.getCurrentPoints();
      this.editedTransfer = null;
      this.editedTransferConfirm = null;
      this.editedIndex = this.dealersList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogTransfer = true;
    },
    executeTransfer() {
      RESTService.checkCurrentPoints(this.userID._id).then(({ data }) => {
        this.currentPoints = data.points;
        if (this.currentPoints < parseInt(this.editedTransfer)) {
          this.$refs.observerTransfer.setErrors({
            editedTransferConfirm: [
              `Your have ${this.numeral(this.currentPoints).format(
                "0,0"
              )} PlusPoints on stock; No enough to make this transfer`,
            ],
          });
        } else {
          RESTService.postTransfer(
            this.userID._id,
            this.editedItem._id,
            parseInt(this.editedTransfer)
          ).then(({ data }) => {
            if (data.ok === true) {
              this.loadData();
              this.successTransfer = true;
              //              this.dialogTransfer = false;
            } else {
              this.alertNoTransfer = true;
            }
          });
        }
      });
    },
    closeSuccessTransfer() {
      this.successTransfer = false;
      this.dialogTransfer = false;
    },
    closeAlert() {
      this.alertNoTransfer = false;
      this.dialogTransfer = false;
    },
    closeTransfer() {
      this.$refs.observerTransfer.reset();
      this.editedTransfer = null;
      this.editedTransferConfirm = null;
      this.dialogTransfer = false;
    },

    editItem(item) {
      this.editedIndex = this.dealersList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedFullName = this.editedItem.fullName;
      this.editedEMail = this.editedItem.eMail;
      this.editedPhoneNumber = this.editedItem.phone;
      this.dialogEditName = true;
    },
    closeEdit() {
      this.dialogEditName = false;
    },
    saveEdit() {
      RESTService.patchEdit(
        this.userID._id,
        this.editedItem._id,
        this.editedFullName,
        this.editedEMail,
        this.editedPhoneNumber
      ).then(({ data }) => {
        if (data.ok === true) {
          this.loadData();
        } else {
          alert("Something went wrong\nPlease Try again later...");
        }
      });
      this.closeEdit();
    },

    deleteItem(item) {
      this.editedIndex = this.dealersList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      RESTService.deleteEdit(this.userID._id, this.editedItem._id).then(() => {
        this.closeDelete();
        this.loadData();
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
  },
};
</script>
