<template>
  <v-container>
    <v-row class="text-left">
      <v-col cols="12">
        <h1>Packages Active</h1>
        <v-divider class="pb-4"></v-divider>
      </v-col>
    </v-row>

    <template v-if="!connectedToInternet">
      <v-row cols="12">
        <v-col cols="12">
          <v-skeleton-loader
            class="mx-auto"
            max-width="320"
            type="card"
            elevation="5"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row cols="12">
        <productAsset
          v-for="product in productList.current"
          :key="product._id"
          :title="product.title"
          :media="product.media"
          :points="product.points"
          :terms="product.terms"
          :sold="product.sold"
          :channels-count="product.channels.length"
          :channels="product.channels.join(', ')"
        >
        </productAsset>
      </v-row>

      <template v-if="!!productList.development">
        <v-row class="text-left pt-12">
          <v-col cols="12">
            <h1>On Development...</h1>
            <v-divider class="pb-4"></v-divider>
          </v-col>
        </v-row>
        <v-row cols="12">
          <productAsset
            v-for="product in productList.development"
            :key="product._id"
            :title="product.title"
            :media="product.media"
            :points="product.points"
            :terms="product.terms"
            :channels-count="product.channels.length"
            :channels="product.channels.join(', ')"
          >
          </productAsset>
        </v-row>
      </template>
    </template>
  </v-container>
</template>

<script>
import { getUserID, connectedToInternet } from "@/store/helpers";
import productAsset from "@/components/productAsset";
import RESTService from "@/services/RESTServices";

export default {
  components: {
    productAsset,
  },
  data: () => ({
    productList: {
      current: [],
      coming: [],
    },
    show: false,
  }),
  computed: {
    ...getUserID,
    ...connectedToInternet,
  },
  created() {
    this.loadData();
    this.interval = setInterval(() => {
      this.loadData();
    }, 5000); //5 sec
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    loadData() {
      RESTService.getProducts(this.userID._id).then(({ data }) => {
        this.productList = data;
      });
    },
  },
};
</script>
