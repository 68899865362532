<template>
  <v-container
    style="background-color: #f4b614; height: 600px"
    fluid
    fill-height
  >
    <v-row justify="center" no-gutters style="height: 430px">
      <v-card max-width="700" min-width="300" class="elevation-7">
        <v-row justify="center" no-gutters style="height: 400px">
          <v-col cols="8" align-self="center">
            <v-row justify="center" no-gutters>
              <v-col cols="7" align-self="center">
                <v-img src="@/assets/liveplus_logo_348x100.png" contain></v-img>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" align-self="center">
            <v-row justify="center" no-gutters>
              <v-img
                v-if="standBy"
                src="@/assets/standby.gif"
                contain
                width="220"
                height="220"
                class="ma-5"
              >
              </v-img>
              <v-img
                v-else
                :src="qrcode"
                contain
                width="220"
                height="220"
                class="ma-5"
              >
              </v-img>
              <v-btn
                :disabled="disabledLoginBtn"
                @click="login"
                rounded
                class="pl-10 pr-10 elevation-4"
                >Login
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <h5 class="pb-3 text-center grey--text">
          © Copyright 2022 LivePlus, Use of this system is subject to LivePlus
          terms of service
        </h5>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import RESTService from "@/services/RESTServices.js";
export default {
  data: () => ({
    disabledLoginBtn: false,
    showPass: false,
    logError: false,
    standBy: true,
    qrcode: null,
    connectedToInternet: true,
  }),
  methods: {
    login() {
      const session = new Date().getTime();
      let code;
      RESTService.getQRCodeLogin(session).then(({ data }) => {
        if (data.ok === true) {
          this.qrcode = data.qrcode; //"http://is.isjust.live/6KWYRY9Y.png";
          code = data.code;
          this.disabledLoginBtn = true;
          this.standBy = false;

          this.timeOut = setTimeout(() => {
            this.standBy = true;
            this.disabledLoginBtn = false;
            clearTimeout(this.timeOut);
            clearInterval(this.pool);
          }, 30000); //30 sec

          this.pool = setInterval(() => {
            RESTService.getLogin(session, code).then(({ data }) => {
              if (data.ok === true) {
                //LOG IN
                clearTimeout(this.timeOut);
                clearInterval(this.pool);
                this.$store.dispatch("login", data).then(() => {
                  this.$router.push({ name: "Dashboard" });
                });
              }
            });
          }, 1000);
        }
      });
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
    clearInterval(this.pool);
  },
};
</script>
