<template>
  <v-container>
    <v-row class="text-left">
      <v-col cols="12">
        <h1>Marketing Material</h1>
        <v-divider class="pb-8"></v-divider>
      </v-col>
    </v-row>

    <!--    <v-row class="text-left">
          <v-col cols="12">
            <file-browser :axiosConfig="{baseURL: 'http://marketing-assets.isjust.live:8081'}" :tree="tree" :uploadingFiles="upload"/>

            <v-divider class="pb-8"></v-divider>
          </v-col>
        </v-row>-->
    <template v-if="!connectedToInternet">
      <v-row cols="12">
        <v-col cols="12">
          <v-skeleton-loader
            class="mx-auto"
            max-width="320"
            type="card"
            elevation="5"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row cols="12">
        <marketingAsset
          v-for="item in marketingList"
          :key="item._id"
          :title="item.title"
          :img="item.img"
          :sub-title="item.subTitle"
          :info="item.info"
          :link="item.link"
        >
        </marketingAsset>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { getUserID, connectedToInternet } from "@/store/helpers";
import marketingAsset from "@/components/marketingAsset";
import RESTService from "@/services/RESTServices";
//import FileBrowser from "vuetify-file-browser";

export default {
  components: {
    marketingAsset,
    //FileBrowser
  },
  data: () => ({
    marketingList: [],
    //    show: false,
    //    tree: false,
    //    upload: false,
  }),
  computed: {
    ...getUserID,
    ...connectedToInternet,
  },
  methods: {
    loadData() {
      RESTService.getMarketing(this.userID._id).then(({ data }) => {
        this.marketingList = data;
        //console.dir(data)
      });
    },
  },
  created() {
    this.loadData();
    this.interval = setInterval(() => {
      this.loadData();
    }, 30000); //5 sec
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
