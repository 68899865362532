import axios from "axios";
import store from "../store/index";

const apiClient = axios.create({
  //baseURL: "http://localhost:3000/LivePlus",
  baseURL: "https://lead.isjust.live/LivePlus",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 4000,
});

apiClient.interceptors.response.use(
  (response) => {
    store.dispatch("connectedToInternet");
    return response;
  },
  (error) => {
    if (!error.response) {
      store.dispatch("noInternet");
    } else if (error.response.status === 401) {
      store.dispatch("logout");
    }
    return Promise.reject(error);
  }
);

export default {
  setJWT(jwt) {
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  },
  checkUniqueUser(userName) {
    return apiClient.get(`/checkUser/${userName}`);
  },
  checkCurrentPoints(userID) {
    return apiClient.get(`/currentPoints/${userID}`);
  },
  getSalesActivity(userID, Zone) {
    return apiClient.get(`/salesActivity/${userID}/${Zone}`);
  },
  getCalculatorData(userID) {
    return apiClient.get(`/calculator/${userID}`);
  },
  getInventory(userID) {
    return apiClient.get(`/inventory/${userID}`);
  },
  getStatus(recordID) {
    return apiClient.get(`/status/${recordID}`);
  },
  getMail(recordID) {
    return apiClient.get(`/mail/${recordID}`);
  },
  getProducts(recordID) {
    return apiClient.get(`/products/${recordID}`);
  },
  getMarketing(recordID) {
    return apiClient.get(`/marketing/${recordID}`);
  },
  getQRCodeLogin(session) {
    return apiClient.get(`/loginQRCode/${session}`);
  },
  getLogin(session, code) {
    return apiClient.get(`/login/${session}/${code}`);
  },
  postMail(userID, data) {
    return apiClient.post(`/mail/${userID}`, data);
  },
  postEMail(userID, toID) {
    return apiClient.post(`/email`, {
      from: userID,
      to: toID,
    });
  },
  postTransfer(userID, teamMemberID, points) {
    return apiClient.post(`/transfer`, {
      _id: userID,
      teamMemberID: teamMemberID,
      points: points,
    });
  },
  patchActivateDeactivateMember(userID) {
    return apiClient.patch(`/activateDeactivateMember/${userID}`);
  },
  patchEdit(userID, memberID, fullName, eMail, phoneNumber) {
    return apiClient.patch(`/dealerMembership`, {
      _id: userID,
      memberID: memberID,
      fullName: fullName,
      eMail: eMail,
      phone: phoneNumber,
    });
  },
  deleteEdit(userID, memberID) {
    return apiClient.delete(`/dealerMembership/${userID}/${memberID}`);
  },
  postNewUser(newUser) {
    console.log("Post");
    console.dir(newUser);
    return apiClient.post(`/dealerMembership`, newUser);
  },
};
