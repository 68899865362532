var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-left"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v(_vm._s(_vm.teamTitle)+" Status")]),_c('v-divider')],1)],1),_c('v-row',{staticClass:"pl-3"},[(!_vm.connectedToInternet)?_c('p',{staticClass:"text-body-2 red--text font-weight-bold"},[_vm._v(" WARNING: Sales Data Disconnected on: "+_vm._s(new Date().toDateString())+", "+_vm._s(new Date().toTimeString())+" ")]):_c('p',{staticClass:"text-body-2"},[_vm._v(" Updated on: "+_vm._s(new Date().toDateString())+", "+_vm._s(new Date().toTimeString())+" ")])]),_c('v-row',{staticClass:"text-left"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"dense":"","item-key":"_id","headers":_vm.headers,"items":_vm.statusList,"items-per-page":-1,"search":_vm.search,"custom-filter":_vm.filterOnlyLowCapsText,"loading":!_vm.connectedToInternet,"loading-text":"Connecting... Please wait","no-data-text":"No Team Members yet, add members on the Team Management page"},scopedSlots:_vm._u([{key:"item.points",fn:function(ref){
var item = ref.item;
return [(item.points < _vm.showColor(item.teamSize))?[_c('v-chip',{attrs:{"color":_vm.getColor(parseInt(item.points), parseInt(item.teamSize)),"small":"","dark":"","outlined":!!!item.points}},[_vm._v(" "+_vm._s(_vm.numeral(item.points).format("0,0"))+" ")])]:[_vm._v(" "+_vm._s(_vm.numeral(item.points).format("0,0"))+" ")]]}},{key:"item.hour",fn:function(ref){
var item = ref.item;
return [(item.hour === _vm.hourMax)?[_c('v-chip',{attrs:{"color":"green","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.numeral(item.hour).format("0,0"))+" ")])]:[_vm._v(" "+_vm._s(_vm.numeral(item.hour).format("0,0"))+" ")]]}},{key:"item.day",fn:function(ref){
var item = ref.item;
return [(item.day === _vm.dayMax)?[_c('v-chip',{attrs:{"color":"green","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.numeral(item.day).format("0,0"))+" ")])]:[_vm._v(" "+_vm._s(_vm.numeral(item.day).format("0,0"))+" ")]]}},{key:"item.week",fn:function(ref){
var item = ref.item;
return [(item.week === _vm.weekMax)?[_c('v-chip',{attrs:{"color":"green","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.numeral(item.week).format("0,0"))+" ")])]:[_vm._v(" "+_vm._s(_vm.numeral(item.week).format("0,0"))+" ")]]}},{key:"item.month",fn:function(ref){
var item = ref.item;
return [(item.month === _vm.monthMax)?[_c('v-chip',{attrs:{"color":"green","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.numeral(item.month).format("0,0"))+" ")])]:[_vm._v(" "+_vm._s(_vm.numeral(item.month).format("0,0"))+" ")]]}},{key:"item.quarter",fn:function(ref){
var item = ref.item;
return [(item.quarter === _vm.quarterMax)?[_c('v-chip',{attrs:{"color":"green","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.numeral(item.quarter).format("0,0"))+" ")])]:[_vm._v(" "+_vm._s(_vm.numeral(item.quarter).format("0,0"))+" ")]]}},{key:"item.year",fn:function(ref){
var item = ref.item;
return [(item.year === _vm.yearMax)?[_c('v-chip',{attrs:{"color":"green","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.numeral(item.year).format("0,0"))+" ")])]:[_vm._v(" "+_vm._s(_vm.numeral(item.year).format("0,0"))+" ")]]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [(item.total === _vm.totalMax)?[_c('v-chip',{attrs:{"color":"green","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.numeral(item.total).format("0,0"))+" ")])]:[_vm._v(" "+_vm._s(_vm.numeral(item.total).format("0,0"))+" ")]]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4 pt-6",attrs:{"append-icon":"mdi-magnify","label":"Search by name"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"body.append",fn:function(){return [_c('tr',[_c('td'),_c('td'),_c('td',[_c('v-text-field',{attrs:{"type":"number","label":"Less than"},model:{value:(_vm.points),callback:function ($$v) {_vm.points=$$v},expression:"points"}})],1),_c('td',{attrs:{"colspan":"2"}})])]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',[_c('p',{staticClass:"font-italic text-caption"},[_vm._v(" NOTES: "),_c('br'),_c('span',{staticClass:"red--text"},[_vm._v("RED")]),_vm._v(" = PlusPoints are only enough for less than two days of operation. "),_c('br'),_c('span',{staticClass:"orange--text"},[_vm._v("ORANGE")]),_vm._v(" = PlusPoints are only enough for less than five days of operation. "),_c('br'),_vm._v(" Table rows are shown on the individual leader's timezone data and might mismatch your local time header's table titles. ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }