<template>
  <div>
    <template>
      <v-row class="text-left">
        <v-col cols="12">
          <v-row class="pt-8 pl-3">
            <h1>Sales</h1>
            <p class="text-caption gray--text font-italic pl-2 pt-2">
              ( Philippines Time )
            </p>
          </v-row>
          <v-row class="pb-5 pl-3">
            <p
              v-if="!connectedToInternet"
              class="text-body-2 red--text font-weight-bold"
            >
              WARNING: Sales Data Disconnected on:
              {{ new Date().toDateString() }}, {{ new Date().toTimeString() }}
            </p>
            <p v-else class="text-body-2">
              Updated at: {{ new Date().toDateString() }},
              {{ new Date().toTimeString() }}
            </p>
            <v-progress-linear
              v-if="!connectedToInternet"
              color="orange lighten-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-row>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <template v-if="showZones && !!sales">
        <v-sheet elevation="5">
          <v-tabs
            dark
            center-active
            background-color="deep-purple lighten-1"
            slider-color="orange"
          >
            <v-tab @click="setZone('ALL')">All Zones</v-tab>
            <v-tab v-for="zone in zones" :key="zone" @click="setZone(zone)"
              >{{ zone }}
            </v-tab>
          </v-tabs>
        </v-sheet>
      </template>

      <!-- MAIN COUNTERS -->
      <template>
        <v-row class="pt-8" v-if="!!sales">
          <v-col v-for="(stat, index) in sales" :key="index">
            <salesCard :stat="stat"></salesCard>
          </v-col>
        </v-row>
        <!-- Team -->
        <v-row class="text-left pt-10">
          <v-col cols="12">
            <h1>Team</h1>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!!total">
            <countCard
              title="Team Size"
              :count="total.count"
              :day-growth="total.dayGrowth"
            ></countCard>
          </v-col>
          <v-col
            v-if="
              !!dealers &&
              (this.userID.type === 'Platform' ||
                this.userID.type === 'Master Dealer')
            "
          >
            <countCard
              title="Dealers"
              :count="dealers.count"
              :day-growth="dealers.dayGrowth"
            ></countCard>
          </v-col>
          <v-col
            v-if="
              !!agents &&
              (this.userID.type === 'Platform' ||
                this.userID.type === 'Master Dealer' ||
                this.userID.type === 'Dealer')
            "
          >
            <countCard
              title="Agents"
              :count="agents.count"
              :day-growth="agents.dayGrowth"
            ></countCard>
          </v-col>
          <v-col
            v-if="
              !!retailers &&
              (this.userID.type === 'Platform' ||
                this.userID.type === 'Master Dealer' ||
                this.userID.type === 'Dealer' ||
                this.userID.type === 'Agent')
            "
          >
            <countCard
              title="Retailers"
              :count="retailers.count"
              :day-growth="retailers.dayGrowth"
            ></countCard>
          </v-col>
        </v-row>
        <v-row class="text-left pt-10">
          <v-col cols="12">
            <h1>Market</h1>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!!users.count">
            <countCard
              title="Active Subscriptions"
              :count="`${numeral(users.count).format('0,0')}`"
              :day-growth="users.dayGrowth"
            ></countCard>
          </v-col>

          <v-col v-if="!!addressableMarket.count">
            <countCard
              title="Active Market"
              :count="`${numeral(addressableMarket.count).format('0,0')}`"
              :day-growth="addressableMarket.dayGrowth"
            ></countCard>
          </v-col>
          <v-col v-if="!!addressableMarket.count && !!users.count">
            <countCard
              title="Active Market Penetration"
              :count="`${numeral(
                (this.users.count / this.addressableMarket.count) * 100
              ).format('0,0.00')}%`"
              :day-growth="0"
            ></countCard>
          </v-col>
        </v-row>
        <!-- Points Inventory -->
        <v-row v-if="!!points" class="text-left pt-10">
          <v-col cols="12">
            <h1>PlusPoints In Stock</h1>
            <h4
              class="grey--text"
              v-if="
                this.userID.type === 'Master Dealer' ||
                this.userID.type === 'Platform'
              "
            >
              (1 PlusPoint = ₱{{
                numeral(PlusPointsPHPCurrency).format("0,0.000")
              }}) *
            </h4>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!!points">
            <countCard
              title="Total Available"
              :count="`${numeral(this.points.count).format('0,0')}`"
              :day-growth="points.dayGrowth"
            ></countCard>
          </v-col>
          <v-col v-if="!!points">
            <countCard
              :title="`Subs Volume (sub:${ProductValue})`"
              :count="`${numeral(
                Math.floor(this.points.count / this.ProductValue)
              ).format('0,0')}`"
              :day-growth="points.dayGrowth"
            ></countCard>
          </v-col>
          <v-col v-if="!!points">
            <countCard
              title="Member Subs Volume"
              :count="`${numeral(
                Math.floor(this.points.count / this.ProductValue / total.count)
              ).format('0,0')}`"
              :day-growth="points.dayGrowth"
            ></countCard>
          </v-col>
          <v-col v-if="!!points">
            <countCard
              title="Subs/Member Days Operation"
              :count="`${numeral(
                this.points.count /
                  this.ProductValue /
                  total.count /
                  ((25 + 40) / 2)
              ).format('0,0')}`"
              :day-growth="points.dayGrowth"
            ></countCard>
          </v-col>
          <v-col v-if="!!points">
            <countCard
              title="PlusPoints per Subscription"
              :count="`${numeral(this.ProductValue).format('0,0')}`"
              :day-growth="points.dayGrowth"
            ></countCard>
          </v-col>
          <v-col
            v-if="
              !!points &&
              (this.userID.type === 'Master Dealer' ||
                this.userID.type === 'Platform')
            "
          >
            <countCard
              title="Subscription in PHPs *"
              :count="`₱${numeral(this.PackagePHP100CH).format('0,0.00')}`"
              :day-growth="points.dayGrowth"
            ></countCard>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <p class="font-italic text-caption pt-6">
              NOTES: <br />
              * Global Currency data is updated as available every 15min. (3rd
              party terms of services apply)
            </p>
          </v-col>
        </v-row>
      </template>
    </template>
  </div>
</template>

<script>
import { connectedToInternet, getUserID, getZones } from "@/store/helpers";
import countCard from "./countCard";
import salesCard from "./salesCard";
import RESTService from "@/services/RESTServices";

export default {
  name: "Dashboard",
  components: { countCard, salesCard },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  data: () => ({
    showZones: false,
    currentZone: "ALL",
    sales: null,
    dealers: null,
    agents: null,
    retailers: null,
    total: null,
    points: null,
    users: null,
    addressableMarket: { count: null, dayGrowth: 0 },
    interval: null,
    PHP: null,
    ProductValue: null,
    PackagePHP100CH: null,
    PlusPointsPHPCurrency: null,
    type: null,
  }),
  computed: {
    ...getUserID,
    ...connectedToInternet,
    ...getZones,
  },
  created() {
    this.loadData();
    this.interval = setInterval(() => this.loadData(), 4000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    loadData() {
      RESTService.getSalesActivity(
        this.userID._id,
        this.currentZone.replace(/\s/, "_").toUpperCase().trim()
      ).then(({ data }) => {
        this.sales = data.dash;
        this.dealers = data.dealers;
        this.agents = data.agents;
        this.retailers = data.retailers;
        this.addressableMarket = data.addressableMarket;
        this.points = data.points;
        this.users = data.users;
        this.total = data.total;
        this.PHP = data.PHP;
        this.ProductValue = data.ProductValue;
        this.PackagePHP100CH = data.PackagePHP100CH;
        this.PlusPointsPHPCurrency = data.PlusPointsPHPCurrency;
        this.type = this.$store.state.user.type;
        if (this.zones) {
          this.showZones = this.zones.length > 1;
        }
      });
    },
    setZone(zone) {
      this.currentZone = zone;
      this.loadData();
    },
  },
};
</script>
