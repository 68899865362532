<template>
  <v-container f-a-q>
    <v-row class="text-left">
      <v-col cols="12">
        <h1>Why L!vePlus</h1>
        <v-divider></v-divider>
        <div class="text-body-1 pa-2" v-html="faq.eng.why"></div>
      </v-col>
    </v-row>

    <v-row class="text-left">
      <v-col cols="12">
        <h1>Frequent Answered Questions</h1>
        <h4>Version: {{ faq.eng.version }} ({{ faq.eng.published }})</h4>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="text-right">
      <v-col>
        <v-spacer></v-spacer>
        <v-btn @click="all">Expand All</v-btn>
        <v-btn @click="none">Collapse</v-btn>
      </v-col>
    </v-row>

    <v-row class="text-left">
      <v-col>
        <v-expansion-panels v-model="panel" focusable multiple>
          <v-expansion-panel
            v-for="(item, index) in faq.eng.items"
            :key="index"
          >
            <v-expansion-panel-header class="text-h6"
              >{{ index + 1 }}) {{ item.q }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-1 pa-4" v-html="item.a">
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row class="text-left">
      <v-col>
        <p>
          Do you have another question? Please send us an eMail to faq@eyeio.com
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import faq from "@/assets/faq.json";

export default {
  name: "FAQ",
  data() {
    return {
      panel: [],
      faq,
    };
  },
  methods: {
    all() {
      this.panel = [...Array(this.faq.eng.items.length).keys()].map(
        (k, i) => i
      );
    },
    none() {
      this.panel = [];
    },
  },
};
</script>
